import React, { ReactNode, useMemo } from 'react';

import { NavModelItem, PageLayoutType } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { Icon, Tab, TabsBar } from '@grafana/ui';

import { ROUTE_TITLE, ROUTES } from '@/constants/routing';
import { useCurrentPage } from '@/hooks/routes';
import { pages } from '@/pages/App/PageDefinitions';
import { prefixRoute } from '@/utils/utils.routing';

type Props = { children: ReactNode; logo: string };
export const PluginWrapper = ({ logo, children }: Props) => {
  const currentPage = useCurrentPage();

  const overviewPage = pages.get(ROUTES.Overview);
  const logVolumeExplorerPage = pages.get(ROUTES.LogVolumeExplorer);

  const pageNav = useMemo(
    () =>
      ({
        text: ROUTE_TITLE.LogVolumeExplorer,
        hideFromBreadcrumbs: true,
        img: logo,
        subTitle: (
          <>
            Insights on volume usage.{' '}
            <a
              className={'external-link'}
              target="_blank"
              href="https://grafana.com/docs/grafana-cloud/cost-management-and-billing/analyze-costs/logs-costs/analyze-log-ingestion-log-volume-explorer/"
              rel="noreferrer"
            >
              Documentation <Icon size="sm" name="external-link-alt" />
            </a>{' '}
          </>
        ) as ReactNode,
      } as NavModelItem),
    [logo]
  );
  return (
    <>
      <PluginPage pageNav={pageNav} layout={PageLayoutType.Standard}>
        <div className={'log-volume-explorer-app'}>
          <TabsBar>
            {overviewPage && (
              <Tab
                key={`tab__${overviewPage.id}`}
                label={overviewPage.text}
                href={prefixRoute(ROUTES.Overview)}
                active={currentPage === overviewPage.id}
              />
            )}
            {logVolumeExplorerPage && (
              <Tab
                key={`tab__${logVolumeExplorerPage.id}`}
                label={logVolumeExplorerPage.text}
                href={prefixRoute(ROUTES.LogVolumeExplorer)}
                active={currentPage === logVolumeExplorerPage.id}
              />
            )}
          </TabsBar>
          {children}
        </div>
      </PluginPage>
    </>
  );
};
