import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Param } from '@/types';

import { buildQueryStringParts } from './logs';

export const useUrlSearchParams = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useUrlSearchParamsUpdate = (param?: Param) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  return {
    urlSet: (param: Param) => {
      const searchParams = new URLSearchParams(search);

      const expr = buildQueryStringParts(param.labelVolume).join(',');
      const date = JSON.stringify({ from: param.timeRange.from, to: param.timeRange.to });
      const raw = JSON.stringify(param.timeRange.raw);

      searchParams.set('expr', expr);
      searchParams.set('rangeRaw', raw);
      searchParams.set('range', date);
      searchParams.set('ds', param.datasource);
      const queryString = searchParams.toString();

      navigate({
        search: queryString.length > 0 ? '?' + queryString : '',
      });
    },
    urlGet: () => {
      const searchParams = new URLSearchParams(search);

      const queryExpr = searchParams.get('expr') || null;
      const rangeRaw = searchParams.get('rangeRaw') || null;
      const range = searchParams.get('range') || null;
      const ds = searchParams.get('ds') || null;

      return {
        queryExpr,
        rangeRaw,
        range,
        ds,
      };
    },
  };
};
