import React, { useContext, useMemo } from 'react';

import { Alert, LoadingPlaceholder, Stack } from '@grafana/ui';

import { LinkToExplore } from '@/components/LogVolume/LinkToExplore';
import { Panel } from '@/components/Panel';
import { CHART } from '@/constants';
import { ParamContext } from '@/context/param-context';
import { useGetVolumeRange } from '@/hooks/logs';
import { useUserPermissions } from '@/hooks/user';
import { useStyles } from '@/styles';

import { getTimeseriesFrame, timeseriesPanelOptions } from '../LogVolume/utils';

export const Timeseries = () => {
  const {
    param: { timeRange },
  } = useContext(ParamContext);
  const styles = useStyles();

  const { canAccessExplore } = useUserPermissions();

  // Get log volume for the selected labels
  const { data, isInitialLoading, isRefetching, isError, error } = useGetVolumeRange();

  const isLoading = isInitialLoading || isRefetching;
  let volumeRangeData = data?.data.result;

  const timeSeriesData = useMemo(() => {
    if (volumeRangeData) {
      // Update the timeseries data for user submit only
      return getTimeseriesFrame(volumeRangeData, timeRange);
    }
    return;
    // By not including timeRange in the useEffect dependencies we prevent the graph from updating without clicking submit
  }, [volumeRangeData]); // eslint-disable-line react-hooks/exhaustive-deps

  if (isError) {
    return (
      <Alert title={error.data.error}>
        <Stack direction="column">
          <div>{error.data.message}</div>
        </Stack>
      </Alert>
    );
  }

  if (isLoading) {
    return <LoadingPlaceholder className={styles.noData} text="Log volume is loading..." />;
  }

  if (!volumeRangeData) {
    return <p>Select a datasource and at least one label to view estimated log volume</p>;
  }

  if (volumeRangeData.length === 0) {
    return <div className={styles.noData}>No data</div>;
  }

  return (
    <>
      Ingestion rate for top 10 logs by volume for the selected labels
      <Panel
        data={timeSeriesData}
        chart={CHART.TIMESERIES}
        title={'Volume Timeseries'}
        options={timeseriesPanelOptions}
      />
      {canAccessExplore && <LinkToExplore />}
    </>
  );
};
