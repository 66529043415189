import React, { useContext } from 'react';

import { TimeRange } from '@grafana/data';
import { TimeRangeInput } from '@grafana/ui';

import { ParamContext } from '@/context/param-context';

export const TimePicker = () => {
  const { param, setParam } = useContext(ParamContext);

  const onTimePickerChange = (timeRange: TimeRange) => {
    setParam({
      ...param,
      timeRange,
    });
  };

  // TODO workaround to use the TimeRangeInput, eventually want TimeRangePicker back
  // return (
  //   <TimeRangePicker
  //     value={param.timeRange}
  //     onChange={onTimePickerChange}
  //     onChangeTimeZone={() => {}}
  //     onZoom={() => {
  //       const newTimeRange = getZoomedTimeRange(param.timeRange);
  //       setParam({
  //         ...param,
  //         timeRange: newTimeRange,
  //       });
  //     }}
  //     onMoveBackward={() => {}}
  //     onMoveForward={() => {}}
  //   />
  // );
  return <TimeRangeInput value={param.timeRange} onChange={onTimePickerChange} />;
};
