import React, { createContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { dateTime, TimeRange } from '@grafana/data';

import { buildExploreLink } from '@/components/LogVolume/LinkToExplore';
import { setInitContextFromUrl } from '@/components/LogVolume/utils';
import { CHART, ONE_HOUR_IN_MS } from '@/constants';
import { DEFAULT_DATASOURCE } from '@/constants/api';
import { buildQueryStringParts } from '@/hooks/logs';
import { useUrlSearchParamsUpdate } from '@/hooks/url.utils';
import { LabelVolume, Param } from '@/types';

interface ParamContextType {
  param: Param;
  setParam: React.Dispatch<React.SetStateAction<Param>>;
  currentExploreLink: string;
  setCurrentExploreLink: React.Dispatch<React.SetStateAction<string>>;
  currentQueryKey: string;
  setCurrentQueryKey: React.Dispatch<React.SetStateAction<string>>;
}

const initialRange: TimeRange = {
  from: ONE_HOUR_IN_MS, // 1h ago in ms
  to: dateTime(),
  raw: {
    from: 'now-1h',
    to: 'now',
  },
};
const initialLabelVolume: LabelVolume[] = [{ id: uuidv4(), label: null, value: null }];

export const initialState: Param = {
  labelVolume: initialLabelVolume,
  badQuery: false,
  datasource: DEFAULT_DATASOURCE,
  timeRange: initialRange,
  selectedChart: CHART.BARCHART,
};

export const buildQueryKey = (param: Param) => {
  return `{${buildQueryStringParts(param.labelVolume).join(',')}}`;
};

export const ParamContext = createContext<ParamContextType>({
  param: initialState,
  setParam: () => {},
  currentExploreLink: '',
  setCurrentExploreLink: () => {},
  currentQueryKey: '',
  setCurrentQueryKey: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const ParamContextProvider = ({ children }: Props) => {
  const { urlGet } = useUrlSearchParamsUpdate();
  const { queryExpr, range, rangeRaw, ds } = urlGet();

  const initContext = setInitContextFromUrl(queryExpr, range, rangeRaw, ds, initialState);

  const [param, setParam] = useState<Param>({
    ...initContext,
  });

  const [currentExploreLink, setCurrentExploreLink] = useState<string>(buildExploreLink(initContext));
  const [currentQueryKey, setCurrentQueryKey] = useState<string>(buildQueryKey(param));
  const value = { param, setParam, currentExploreLink, setCurrentExploreLink, currentQueryKey, setCurrentQueryKey };

  return <ParamContext.Provider value={value}>{children}</ParamContext.Provider>;
};
