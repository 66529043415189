import { formatISO, parseISO } from 'date-fns';
import { drop as _drop } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
  DataFrame,
  DateTime,
  dateTime,
  Field,
  FieldType,
  LoadingState,
  PanelData,
  TimeRange,
  toDataFrame,
} from '@grafana/data';
import { LegendDisplayMode, SortOrder, TooltipDisplayMode, VisibilityMode, VizOrientation } from '@grafana/schema';

import { LabelVolume, Param } from '@/types';
import { BarChartPanelOptions } from '@/types/barChart';
import {
  LogVolumeMetric,
  LogVolumeMetricValueTimeSeries,
  LogVolumeRangeMetricValueTimeSeries,
} from '@/types/logVolume';

export const barChartPanelOptions: BarChartPanelOptions = {
  barWidth: 0.7,
  legend: {
    showLegend: true,
    calcs: [],
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
  },
  orientation: VizOrientation.Horizontal,
  showValue: VisibilityMode.Never,
  xTickLabelRotation: 0,
  xTickLabelSpacing: 80,
};

export const timeseriesPanelOptions = {
  legend: {
    showLegend: true,
    displayMode: LegendDisplayMode.List,
    placement: 'bottom',
    calcs: [],
  },
  tooltip: {
    mode: TooltipDisplayMode.Multi,
    sort: SortOrder.None,
  },
  graphStyle: {
    points: true,
  },
};

export const formatLabel = (item: { metric: LogVolumeMetric }) => {
  let labels = '';
  const total = Object.keys(item.metric).length;
  for (const key in item.metric) {
    let label = `${key}`;
    if (item.metric[key]) {
      label += `=${item.metric[key]}`;
    }
    if (key === Object.keys(item.metric)[total - 1]) {
      labels += label;
    } else {
      labels += `${label}, `;
    }
  }
  return labels;
};

const convertValuesToBytesPerSecond = (item: LogVolumeRangeMetricValueTimeSeries, timeRange: TimeRange) => {
  const values: Array<{ timestamp: number; value: number }> = [];
  item.values
    .map((value) => ({ timestamp: value[0], value: parseInt(value[1], 10) }))
    .forEach(({ timestamp, value }, i, arr) => {
      if (i === 0) {
        const sizeOfRangeInSeconds = timestamp - timeRange.from.unix();
        // TODO: https://github.com/grafana/log-volume-explorer/issues/108
        if (!sizeOfRangeInSeconds) {
          values.push({ timestamp, value: value / sizeOfRangeInSeconds });
        } else {
          // The items timestamp equals the start of the query range. This is an error in Loki. The values should be
          // using the end of the time range as the timestamp.
          values.push({ timestamp, value: -1 });
        }
      } else {
        const sizeOfRangeInSeconds = timestamp - arr[i - 1].timestamp;
        values.push({ timestamp, value: value / sizeOfRangeInSeconds });
      }
    });
  return values;
};

export const getTimeseriesFrame = (data: LogVolumeRangeMetricValueTimeSeries[], timeRange: TimeRange) => {
  let allFrames: DataFrame[] = [];
  data.forEach((item) => {
    const label = formatLabel(item);

    // TODO: https://github.com/grafana/log-volume-explorer/issues/108
    const values = _drop(convertValuesToBytesPerSecond(item, timeRange), 1);

    const frame = toDataFrame({
      name: 'timeseries',
      fields: [
        {
          name: 'Time',
          type: FieldType.time,
          values: values.map((value) => {
            return value.timestamp * 1000;
          }),
        },
        {
          name: 'Value',
          type: FieldType.number,
          labels: {
            name: label,
          },
          config: {
            color: {
              mode: 'palette-classic',
            },
            unit: 'binBps',
            mappings: [],
            min: 0,
          },
          values: values.map((value) => value.value),
        },
      ],
    });

    allFrames.push(frame);
  });

  const panelData: PanelData = {
    state: LoadingState.Done,
    series: allFrames,
    timeRange: timeRange,
  };

  return panelData;
};

export const getBarChartFrame = (data: LogVolumeMetricValueTimeSeries[] | undefined) => {
  if (!data) {
    return;
  }
  let fields: Field[] = [];

  // Empty field for each bar since dataframes expects two fields
  // https://grafana.com/developers/plugin-tools/how-to-guides/data-source-plugins/create-data-frames

  fields.push({
    name: 'Label',
    type: FieldType.string,
    values: [''],
    config: {},
  });

  data.forEach((item) => {
    const label = formatLabel(item);
    const volume = parseInt(item.value[1], 10);

    fields.push({
      name: label,
      type: FieldType.number,
      config: {
        color: {
          mode: 'palette-classic',
        },
        unit: 'bytes',
      },
      values: [volume],
    });
  });

  const frame = toDataFrame({
    name: 'bar_chart',
    fields: fields,
  });

  const panelData: PanelData = {
    state: LoadingState.Done,
    series: [frame],
    timeRange: createTimeRange(),
  };

  return panelData;
};
export const toISODate = (value: any): any => {};

export const createTimeSeriesRange = (from: DateTime | string, to: DateTime | string): TimeRange => ({
  from: dateTime(from),
  to: dateTime(to),
  raw: { from, to },
});

const createTimeRange = () => {
  const fakeTimestamp = parseISO(formatISO(Date.now())).getTime();

  const from = Math.min(0, fakeTimestamp);
  const to = Math.max(Date.now(), fakeTimestamp);

  return {
    from: dateTime(from),
    to: dateTime(to),
    raw: {
      from: formatISO(from),
      to: formatISO(to),
    },
  };
};

const lengthOfTimeRange = (range: TimeRange): number => {
  return range.to.valueOf() - range.from.valueOf();
};

export const getZoomedTimeRange = (range: TimeRange, factor = 2): TimeRange => {
  const timespan = lengthOfTimeRange(range);
  const center = range.to.valueOf() - timespan / 2;

  const to = center + (timespan * factor) / 2;
  const from = center - (timespan * factor) / 2;

  return { from: dateTime(from), to: dateTime(to), raw: { from: formatISO(from), to: formatISO(to) } };
};

export const setInitContextFromUrl = (
  queryExpr: string | null,
  range: string | null,
  rangeRaw: string | null,
  ds: string | null,
  initialState: Param
) => {
  let newLabelVolume: LabelVolume[] = [];

  if (queryExpr) {
    newLabelVolume = [];
    queryExpr.split(',').forEach((labelValuePair) => {
      labelValuePair = labelValuePair.replace(/"/g, '');
      const [label, value] = labelValuePair.split('=');
      let newValue = null;
      if (value !== '~.+') {
        newValue = value;
      }
      newLabelVolume.push({ id: uuidv4(), label, value: newValue });
    });
  }
  let newTimeRange = null;

  if (range && rangeRaw) {
    newTimeRange = {
      from: dateTime(JSON.parse(range).from),
      to: dateTime(JSON.parse(range).to),
      raw: {
        from: JSON.parse(rangeRaw).from,
        to: JSON.parse(rangeRaw).to,
      },
    };
  }

  let newDs = null;
  if (ds) {
    newDs = ds;
  }

  return {
    ...initialState,
    ...(newLabelVolume.length > 0 && { labelVolume: newLabelVolume }),
    ...(newTimeRange && { timeRange: newTimeRange }),
    ...(newDs && { datasource: newDs }),
  };
};
