import { DateTime } from '@grafana/data';

// Loki api requires timestamps in nanosecond Unix epoch
export const timeToUnixEpoch = (time: DateTime) => {
  return time.unix() * 1e9;
};

export const getPluginDataProxyUrl = (datasourceUid: string) => {
  return `/api/datasources/proxy/uid/${datasourceUid}/loki`;
};
