import React, { useContext } from 'react';

import { BarChart } from '@/components/BarChart';
import { ActionBar } from '@/components/LogVolume/ActionBar';
import { GraphPicker } from '@/components/LogVolume/GraphPicker';
import { Timeseries } from '@/components/Timeseries';
import { CHART } from '@/constants';
import { ParamContext } from '@/context/param-context';
import { useGetVolume } from '@/hooks/logs';
import { useStyles } from '@/styles';

export const LogVolumeExplorer = () => {
  const styles = useStyles();
  const {
    param: { selectedChart },
  } = useContext(ParamContext);

  return (
    <div className={styles.container}>
      <ActionBar />
      <hr />
      <GraphPicker />
      {selectedChart === CHART.BARCHART && (
        <BarChart header="Top 10 logs by volume for the selected labels" hook={useGetVolume} />
      )}
      {selectedChart === CHART.TIMESERIES && <Timeseries />}
    </div>
  );
};
