import React, { useContext } from 'react';

import { SelectableValue } from '@grafana/data';
import { RadioButtonGroup } from '@grafana/ui';

import { testIds } from '@/components/testIds';
import { ParamContext } from '@/context/param-context';
import { useStyles } from '@/styles';
import { ChartType } from '@/types';

const options: Array<SelectableValue<ChartType>> = [
  { label: 'Total Volume', value: 'barchart' },
  { label: 'Ingest rate over time', value: 'timeseries' },
];
export const GraphPicker = () => {
  const { param, setParam } = useContext(ParamContext);
  const styles = useStyles();

  return (
    <div className={styles.graphPicker}>
      <RadioButtonGroup<ChartType>
        data-testid={testIds.lve.toggleGraphBtn}
        options={options}
        value={param.selectedChart}
        onChange={(option) => {
          setParam({ ...param, selectedChart: option });
        }}
      />
    </div>
  );
};
