import React from 'react';

import { AppRootProps } from '@grafana/data';

export const PluginPropsContext = React.createContext<AppRootProps | null>(null);

type Props = {
  appProps: AppRootProps;
  children: React.ReactNode;
};
export const PluginPropsContextProvider = ({ children, appProps }: Props) => {
  return <PluginPropsContext.Provider value={appProps}>{children}</PluginPropsContext.Provider>;
};
