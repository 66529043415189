import { QueryClient } from '@tanstack/react-query';

const THREE_HOURS = 1000 * 60 * 60 * 3;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      retryOnMount: false,
      staleTime: THREE_HOURS,
    },
  },
});
