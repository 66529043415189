import pluginJson from '../plugin.json';

export const PLUGIN_BASE_URL = `/a/${pluginJson.id}`;

export enum ROUTES {
  Home = 'home',
  LogVolumeExplorer = 'log-volume-explorer',
  Overview = 'overview',
}

export enum ROUTE_TITLE {
  LogVolumeExplorer = 'Log Volume Explorer',
  Overview = 'Overview',
}
