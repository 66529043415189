import React, { useEffect, useState } from 'react';
import { cx } from '@emotion/css';
import { includes as _includes } from 'lodash';

import { config } from '@grafana/runtime';

import { BarChart } from '@/components/BarChart';
import { DEFAULT_DATASOURCE } from '@/constants/api';
import { useGetTopVolume } from '@/hooks/logs';
import { useStyles } from '@/styles';

export const Overview = () => {
  const styles = useStyles();
  const { data: topVolumeData, refetch: refetchTopVolumeRange } = useGetTopVolume();
  const [hasCloudLogsDatasource, setHasCloudLogsDatasource] = useState(false);

  useEffect(() => {
    if (
      _includes(
        Object.entries(config.datasources).map(([_, ds]) => ds.uid),
        DEFAULT_DATASOURCE
      )
    ) {
      setHasCloudLogsDatasource(true);
      if (!topVolumeData?.data.result.length) {
        refetchTopVolumeRange();
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.overviewContainer}>
      <div className={cx(styles.container, styles.fullSpan)}>
        <h3>What is Log Volume Explorer?</h3>

        <ul className={styles.list}>
          <li>
            Log Volume Explorer will help you understand where your logs data is coming from within your organization,
            based on log labels.
          </li>
          <li>View volume as either an aggregation or over time</li>
        </ul>
      </div>
      <div className={cx(styles.container, styles.gettingStartedResponsive)}>
        <h3>Get started</h3>

        <ol className={styles.list}>
          <li>Select a datasource and time range.</li>
          <li>Choose a label(s) to view estimated volume.</li>
          <li>Optionally add filter(s) to refine your results.</li>
          <li>Display results as either an aggregation or time series.</li>
          <li>Share insight with your organization.</li>
        </ol>
      </div>
      <div className={styles.videoBlockResponsive}>
        <iframe
          title="Log Volume Explorer - introductory video"
          src="https://player.vimeo.com/video/878062753?transparent=0"
          allow="autoplay; fullscreen"
          allowFullScreen
          // This is necessary because color-scheme defined on :root has impact on iframe elements
          // More about how color-scheme works for iframes https://github.com/w3c/csswg-drafts/issues/4772
          // Summary: If the color scheme of an iframe differs from embedding document iframe gets an opaque canvas bg appropriate to its color scheme
          style={{ border: 'none', colorScheme: 'light dark' }}
        ></iframe>
      </div>
      {hasCloudLogsDatasource && (
        <div className={cx(styles.container, styles.fullSpan)}>
          <BarChart overviewPage={true} header="Top labels by volume over last hour" hook={useGetTopVolume} />
        </div>
      )}
    </div>
  );
};
