import { ROUTE_TITLE, ROUTES } from '@/constants/routing';

import { LogVolumeExplorer } from '../LogVolumeExplorer';
import { Overview } from '../Overview';

export const pages = new Map([
  [
    ROUTES.Overview,
    {
      id: ROUTES.Overview,
      text: ROUTE_TITLE.Overview,
      component: Overview,
    },
  ],
  [
    ROUTES.LogVolumeExplorer,
    {
      id: ROUTES.LogVolumeExplorer,
      text: ROUTE_TITLE.LogVolumeExplorer,
      component: LogVolumeExplorer,
    },
  ],
]);
