import * as React from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Navigate, Route, Routes } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { AppRootProps } from '@grafana/data';
import { useTheme2 } from '@grafana/ui';

import { PluginWrapper } from '@/components/PluginWrapper';
import { ROUTES } from '@/constants/routing';
import { ParamContextProvider } from '@/context/param-context';
import { PluginPropsContextProvider } from '@/context/plugin-props-context';
import { pages } from '@/pages/App/PageDefinitions';
import { queryClient } from '@/utils/state';
import { prefixRoute } from '@/utils/utils.routing';

const AppRoutes = () => {
  return (
    <Routes>
      {Array.from(pages.values()).map(({ id, component }) => {
        const Component = component;
        return <Route key={id} path={`${id}`} element={<Component />} />;
      })}
      <Route path="*" element={<Navigate to={prefixRoute(ROUTES.Overview)} />} />
    </Routes>
  );
};

export const App = (props: AppRootProps) => {
  const theme = useTheme2();

  return (
    <PluginPropsContextProvider appProps={props}>
      <ParamContextProvider>
        <QueryClientProvider client={queryClient}>
          <SkeletonTheme
            baseColor={theme.colors.background.secondary}
            highlightColor={theme.colors.emphasize(theme.colors.background.secondary)}
            borderRadius={theme.shape.borderRadius()}
          >
            <PluginWrapper logo={props.meta.info.logos.large}>
              <AppRoutes />
            </PluginWrapper>
          </SkeletonTheme>
          {/* Show tanstack query in dev mode */}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ParamContextProvider>
    </PluginPropsContextProvider>
  );
};
