import React, { useContext } from 'react';
import {
  ControllerRenderProps,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormClearErrors,
  UseFormSetValue,
} from 'react-hook-form';

import { SelectableValue } from '@grafana/data';
import { Select } from '@grafana/ui';

import { testIds } from '@/components/testIds';
import { ParamContext } from '@/context/param-context';
import { useGetLogLabels } from '@/hooks/logs';

type compontentArgs = {
  id: string;
  label: string | undefined | null;
  field: ControllerRenderProps<FieldValues, string>;
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
  clearErrors: UseFormClearErrors<FieldValues>;
};
export const LabelSelect = ({ id, label, field, setValue, errors, clearErrors }: compontentArgs) => {
  const {
    param,
    param: { labelVolume },
    setParam,
  } = useContext(ParamContext);

  // Label Select Data
  const { data: labels, isLoading: loadingLabels, isError } = useGetLogLabels(id, param);

  // Label Select Options
  const labelOptions = (labels && labels.map((label) => ({ value: label, label }))) || [{ value: '', label: '' }];
  const isDisabled = isError || loadingLabels || labelOptions.length <= 0;

  // Label already exists set the label
  if (label) {
    setValue(id, label);
  }

  return (
    <span data-testid={testIds.lve.labelSelect}>
      <Select
        {...field}
        width={30}
        disabled={isDisabled}
        invalid={errors.label ? true : false}
        options={labelOptions}
        isLoading={loadingLabels}
        value={label ? { value: label, label } : null}
        onChange={(selectedOption: SelectableValue<string>) => {
          // Update the context label values
          const updatedLabelVolume = labelVolume.map((labelValue) => {
            if (labelValue.id === id) {
              return {
                ...labelValue,
                label: selectedOption.value,
              };
            }
            return labelValue;
          });
          // Update react-form-hook
          setValue(id, selectedOption.value);
          clearErrors(id);
          // Update context
          setParam({
            ...param,
            labelVolume: [...updatedLabelVolume],
          });
        }}
        placeholder={isError ? 'No labels' : 'Select label'}
      />
    </span>
  );
};
