import React, { useContext } from 'react';
import {
  Control,
  Controller,
  DeepMap,
  FieldError,
  FieldValues,
  UseFormClearErrors,
  useFormContext,
  UseFormSetValue,
} from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import { Button, Field, FieldValidationMessage, Icon, Label, Spinner, Stack } from '@grafana/ui';

import { testIds } from '@/components/testIds';
import { ParamContext } from '@/context/param-context';
import { useStyles } from '@/styles';
import { LabelVolume } from '@/types';

import { LabelSelect } from './LabelSelect';
import { LabelValueSelect } from './LabelValueSelect';

type reactHookForm = {
  isLoading: boolean;
  clearErrors: UseFormClearErrors<FieldValues>;
  control: Control<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  errors: DeepMap<FieldValues, FieldError>;
};

export const Labels = ({ isLoading, clearErrors, control, setValue, errors }: reactHookForm) => {
  const styles = useStyles();
  const { register } = useFormContext(); // retrieve all hook methods

  const {
    param,
    param: { labelVolume },
    setParam,
  } = useContext(ParamContext);

  const CreateLabelValueDOM = (labelValue: LabelVolume) => {
    const { id, label, value } = labelValue;
    return (
      <>
        <div className={styles.selectContainer} id={id}>
          <div className={styles.selectGroup}>
            <Field {...register(id)}>
              <Controller
                name={id}
                control={control}
                render={({ field }) => (
                  <>
                    <LabelSelect
                      id={id}
                      label={label}
                      field={field}
                      setValue={setValue}
                      errors={errors}
                      clearErrors={clearErrors}
                    />
                    {errors && errors[id] && (
                      <div className={styles.errorMessage}>
                        <FieldValidationMessage>Select a label</FieldValidationMessage>
                      </div>
                    )}
                  </>
                )}
              />
            </Field>
            <LabelValueSelect id={id} value={value} />
            {labelVolume.length > 1 && (
              <Button
                className={styles.deleteButton}
                disabled={labelVolume.length <= 1}
                data-testid={testIds.lve.deleteQueryBtn}
                variant="secondary"
                onClick={() => {
                  if (labelVolume.length > 1) {
                    deleteLabelValue(id);
                  }
                }}
              >
                <Icon name="times" />
              </Button>
            )}
          </div>
        </div>
      </>
    );
  };

  const deleteLabelValue = (id: string) => {
    const deletedLabelVolume = labelVolume.filter((labelValue) => labelValue.id !== id);
    const newParams = {
      ...param,
      labelVolume: [...deletedLabelVolume],
    };
    setParam(newParams);
  };

  return (
    <>
      {' '}
      <div className={styles.filterLabels}>
        <Label className={styles.groupByLabel}>Label</Label>
        <Label>Group by value (optional)</Label>
      </div>
      {labelVolume.map((labelValue) => {
        return CreateLabelValueDOM(labelValue);
      })}
      <div className={styles.filterContainer}>
        <Stack>
          <Button
            disabled={param.labelVolume && param.labelVolume[0].label !== null ? false : true}
            variant="primary"
            type="submit"
          >
            Submit
            {isLoading && <Spinner className={styles.spinner} />}
          </Button>
          <Button
            className={styles.addButton}
            variant="secondary"
            data-testid={testIds.lve.addQueryBtn}
            onClick={() => {
              const id = uuidv4();
              setParam({
                ...param,
                labelVolume: [...labelVolume, { id, label: null, value: null }],
              });
            }}
          >
            Add Filter
          </Button>
        </Stack>
      </div>
    </>
  );
};
