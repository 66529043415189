import React, { useContext } from 'react';

import { SelectableValue } from '@grafana/data';
import { Field, Select } from '@grafana/ui';

import { testIds } from '@/components/testIds';
import { ParamContext } from '@/context/param-context';
import { useGetLogLabelValues } from '@/hooks/logs';

type Props = {
  id: string;
  value: string | undefined | null;
};

export const LabelValueSelect = ({ id, value }: Props) => {
  const {
    param,
    param: { labelVolume },
    setParam,
  } = useContext(ParamContext);

  // Label Value select
  const selected = labelVolume.filter((labelValue) => {
    if (labelValue.id === id) {
      return labelValue;
    }
    return;
  });

  const selectedLabel = selected[0]?.label || null; // set to null and don't call useGetLogLabelValues if no label is selected

  // Label Value Select Data
  const { data: labelValues, isLoading: loadingLabelValues, isError } = useGetLogLabelValues(selectedLabel, param);
  // Label Value Select Options
  let labelValueOptions = (labelValues && labelValues.map((value) => ({ value: value, label: value }))) || [
    { value: '', label: '' },
  ];
  labelValueOptions.unshift({ value: '', label: 'All values' });
  const isDisabled = isError || loadingLabelValues || labelValueOptions.length <= 0;

  return (
    <Field>
      <span data-testid={testIds.lve.labelValueSelect}>
        <Select
          width={30}
          isClearable
          disabled={isDisabled}
          value={value ? { label: value, value } : null}
          options={labelValueOptions}
          isLoading={loadingLabelValues}
          onChange={(selectedOption: SelectableValue<string>) => {
            // Update the context label values
            const updatedLabelVolume = labelVolume.map((labelValue) => {
              if (labelValue.id === id) {
                return {
                  ...labelValue,
                  value: selectedOption ? selectedOption.value : null,
                };
              }
              return labelValue;
            });
            setParam({
              ...param,
              labelVolume: [...updatedLabelVolume],
            });
          }}
          placeholder={isError ? 'No label values' : 'All values'}
        />
      </span>
    </Field>
  );
};
