import { formatISO, parseISO } from 'date-fns';

import { dateTime } from '@grafana/data';

export const DATASOURCE_REF = {
  uid: 'gdev-testdata',
  type: 'testdata',
};

export const ONE_HOUR_IN_MS = dateTime(parseISO(formatISO(Date.now() - 1000 * 60 * 60)).getTime()); // 1h ago

export enum CHART {
  BARCHART = 'barchart',
  TIMESERIES = 'timeseries',
}

export const LOKI_INTERNAL_LABELS = ['__stream_shard__'];
