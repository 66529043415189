import React, { useRef, useState } from 'react';
import { useResizeObserver } from '@react-aria/utils';

import { PanelData } from '@grafana/data';
import { PanelRenderer } from '@grafana/runtime';
import { PanelChrome } from '@grafana/ui';

import { barChartPanelOptions, timeseriesPanelOptions } from '@/components/LogVolume/utils';
import { ChartType } from '@/types';

type Props = {
  data: PanelData | undefined;
  chart: ChartType;
  title: string;
  options: typeof barChartPanelOptions | typeof timeseriesPanelOptions;
};

export const Panel = ({ data, chart, title, options }: Props) => {
  const panelRendererRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);

  useResizeObserver({
    ref: panelRendererRef,
    onResize: () => {
      const element = panelRendererRef.current;

      if (element) {
        setWidth(element.clientWidth);
      }
    },
  });

  return (
    <div ref={panelRendererRef}>
      <PanelChrome width={width} height={400}>
        {(innerWidth, innerHeight) => {
          return (
            <PanelRenderer
              title={title}
              options={options}
              pluginId={chart}
              width={innerWidth}
              height={innerHeight}
              data={data}
            />
          );
        }}
      </PanelChrome>
    </div>
  );
};
